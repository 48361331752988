import { useAuth } from "auth/use-auth";
import { useQuery } from "@tanstack/react-query";
import { queryFestivalsUpcoming } from "api/query/query-festivals-upcoming";

import { FestivalsRow } from "views/components/festivals-row/festivals-row";

export const loader =
  ({ queryClient, user }) =>
  async () => {
    const query = queryFestivalsUpcoming({ user });

    return queryClient.ensureQueryData(query);
  };

export default function Festivals() {
  const { user } = useAuth();
  const { data: festivals } = useQuery(queryFestivalsUpcoming({ user }));

  return (
    <>
      Upcoming Festivals
      <FestivalsRow festivals={festivals} />
    </>
  );
}