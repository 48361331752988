import { LazyLoadImage } from "react-lazy-load-image-component";
import ModalImage from "react-modal-image";

export const FestivalPoster = ({ edition, zoom }) => {
  const alt = `${edition.name} Festival Poster`;
  const src = `/images/posters/${edition.poster}`;

  if (!edition.poster) {
    return null;
  }

  if (zoom) {
    return <ModalImage alt={alt} small={src} large={src} />
  }

  return <LazyLoadImage alt={alt} src={src} width="100%" />;
};
