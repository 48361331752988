import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

export const SignUp = () => {
	const authentication = getAuth();

	const handleSubmit = async (event) => {
		event.preventDefault();

		const email = event.target.email.value;
		const password = event.target.password.value;

		await createUserWithEmailAndPassword(authentication, email, password).then(
			(response) => {
				console.log(response);
			},
		);
	};

	return (
		<form id="sign-up" onSubmit={handleSubmit}>
			<Box display="inline" mr={2}>
				<TextField
					label="Email"
					name="email"
					type="text"
					size="small"
					sx={{ maxWidth: 150 }}
					variant="standard"
				/>
			</Box>

			<TextField
				label="Password"
				name="password"
				type="password"
				size="small"
				sx={{ maxWidth: 100 }}
				variant="standard"
			/>

			<Button type="submit">Sign Up</Button>
		</form>
	);
};
