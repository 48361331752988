import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { Link } from "react-router-dom";

import { FestivalPoster } from "views/components/festival-poster/festival-poster";

import { getDateSpanString } from 'utils/dates/get-date-span-string';

export const FestivalsRow = ({ center, festivals, upcoming }) => {
  if (!festivals || !festivals.length) {
    return;
  }

  return (
    <Grid container spacing={2} justifyContent={center ? "center" : ""}>
      {festivals?.map((edition) => {
        return (
          <Grid item key={edition.slug} xs={12} sm={4}>
            <Box mb={5}>
              <Link to={`/festival/${edition.slug}`}>
                <Box textAlign="center">
                  <Typography variant="subtitle1">{edition.name}</Typography>
                </Box>

                {edition.poster && !upcoming && (
                  <Box mt={0.5}>
                    <FestivalPoster edition={edition} />
                  </Box>
                )}
              </Link>

              {upcoming && (
                <>
                  <Box textAlign="center">
                    {getDateSpanString(edition)}
                  </Box>

                  <Box mt={1}>
                    <Link to={`/festival/${edition.slug}`}>
                      {edition.poster && <FestivalPoster edition={edition} />}
                    </Link>
                  </Box>
                </>
              )}
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};
