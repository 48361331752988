import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { ScrollToTop } from "./components/scroll-to-top/scroll-to-top";
import { Header } from './components/header/header';

export const Layout = ({ children }) => {
  return (
    <ScrollToTop>
      <CssBaseline />
      <Container disableGutters maxWidth="md">
        <Box mx={1}>
          <Header />
          {children}
        </Box>
      </Container>
    </ScrollToTop>
  );
}
