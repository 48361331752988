import { useAuth } from "auth/use-auth";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { queryFestivalEdition } from "api/query/query-festival-edition";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { FestivalPoster } from "views/components/festival-poster/festival-poster";

import { FestivalHeader } from "views/festival/components/festival-header/festival-header";
import { FestivalEditionUserActions } from "views/components/user-actions/festival-edition-user-actions/festival-edition-user-actions";
import { ButtonWereYouThere } from "views/festival-edition/components/button-were-you-there/button-were-you-there";
import { UserRow } from "views/festival-edition/components/user-row/user-row";
import { SelectPreviousEdition } from "views/festival-edition/components/select-previous-edition/select-previous-edition";
import { ArtistList } from 'views/festival-edition/components/artist-list/artist-list';

import useSwipe from "hooks/use-swipe/use-swipe";

import { getDateSpanString } from "utils/dates/get-date-span-string";

export const loader =
  ({ queryClient, user }) =>
  async ({ params }) => {
    const query = queryFestivalEdition({
      slug: `${params.slug}/${params["*"]}`,
      user,
    });

    return queryClient.ensureQueryData(query);
  };

export const FestivalEdition = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const params = useParams();
  const slug = `${params.slug}/${params["*"]}`;
  const { data } = useQuery(queryFestivalEdition({ slug, user }));

  const previousEdition =
    data?.festival.editions.map((edition) => edition.slug).indexOf(data.slug) +
    1;
  const nextEdition =
    data?.festival.editions.map((edition) => edition.slug).indexOf(data.slug) -
    1;

  const navigateToNextFestival = () => {
    if (data?.festival.editions[nextEdition].slug) {
      navigate(`/festival/${data?.festival.editions[nextEdition].slug}`);
    }
  };

  const navigateToPreviousFestival = () => {
    if (data?.festival.editions[previousEdition].slug) {
      navigate(`/festival/${data?.festival.editions[previousEdition].slug}`);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowLeft") {
      navigateToPreviousFestival();
    } else if (event.key === "ArrowRight") {
      navigateToNextFestival();
    }
  };

  document.onkeydown = handleKeyDown;

  const swipeHandlers = useSwipe({
    onSwipedLeft: () => {
      navigateToNextFestival();
    },
    onSwipedRight: () => {
      navigateToPreviousFestival();
    },
  });

  if (!data) {
    return null;
  }

  return (
    <>
      <div {...swipeHandlers}>
        <Box mb={1}>
          <Grid container justifyContent="space-between">
            <Grid item order={{ xs: 2, sm: 1 }}>
              <Typography variant="h5">{data?.name}</Typography>

              <Box>{getDateSpanString(data)}</Box>
            </Grid>

            <Grid item xs={12} sm="auto" order={{ xs: 1, sm: 2 }}>
              <SelectPreviousEdition festival_edition={data} />
            </Grid>
          </Grid>

          <Box mt={3} mb={2}>
            <ButtonWereYouThere festival_edition={data} />
          </Box>

          <UserRow users={data.users} usersCount={data.usersCount} />
        </Box>

        <Grid container spacing={5}>
          {data?.posters[0]?.filename && (
            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  position: {
                    sm: "sticky",
                  },
                  top: { sm: "1rem" },
                }}
              >
                <FestivalPoster
                  edition={{
                    name: data?.name,
                    poster: data?.posters[0]?.filename,
                  }}
                  zoom
                />
              </Box>
            </Grid>
          )}

          {data?.artists.length > 0 && (
            <Grid item xs>
              <ArtistList artists={data?.artists} festival_edition_slug={data?.slug} />
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
};
