import { useAuth } from "auth/use-auth";
import { useQuery } from "@tanstack/react-query";
import { queryGravatarProfile } from "api/query/query-gravatar-profile";
import { sha256 } from 'js-sha256';

export const GravatarProfile = () => {
  const { user } = useAuth();
  const { data } = useQuery(queryGravatarProfile({ hash: sha256(user.email) }));

  console.log({ data });

  return null;
}