// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD8t9EoW0dMd9YR08Ho0tIy0FCK-LcfDJA",
  authDomain: "mfests-235a0.firebaseapp.com",
  projectId: "mfests-235a0",
  storageBucket: "mfests-235a0.appspot.com",
  messagingSenderId: "309508154665",
  appId: "1:309508154665:web:64687acf29f31502738338",
  measurementId: "G-Y5FM7J7Q0P"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
