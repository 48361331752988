import { useAuth } from "auth/use-auth";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import dayjs from "dayjs";

import { queryFestival } from "api/query/query-festival";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import { FestivalHeader } from "./components/festival-header/festival-header";
import { FestivalsRow } from "views/components/festivals-row/festivals-row";

export const loader =
  ({ queryClient, user }) =>
  async ({ params }) => {
    const query = queryFestival({ slug: params.slug, user });

    return queryClient.ensureQueryData(query);
  };

export const Festival = () => {
  const { user } = useAuth();
  const params = useParams();
  const { data } = useQuery(queryFestival({ slug: params.slug, user }));

  const today = dayjs();

  const upcomingFestivals = data?.editions
    .filter((edition) => today.isBefore(edition.end_date))
    .sort((a, b) => dayjs(a.start_date) - dayjs(b.start_date));
  const pastFestivals = data?.editions.filter((edition) =>
    today.isAfter(edition.end_date),
  );

  return (
    <>
      <Box mb={3}>
        <FestivalHeader festival={data} />
      </Box>

      <FestivalsRow center festivals={upcomingFestivals} upcoming />

      <Box p={5}>
        <Divider />
      </Box>

      <FestivalsRow festivals={pastFestivals} />
    </>
  );
};
