import { useAuth } from "auth/use-auth";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { queryArtist } from "api/query/query-artist";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { ArtistImage } from "views/artist/components/artist-image/artist-image";
import { ArtistUserActions } from "views/components/user-actions/artist-user-actions/artist-user-actions";
import { FestivalsRow } from "views/components/festivals-row/festivals-row";

export const loader =
  (queryClient) =>
  async ({ params }) => {
    const query = queryArtist({ slug: params.slug });

    return queryClient.ensureQueryData(query);
  };

export default function Artist() {
  const { user } = useAuth();
  const params = useParams();
  const { data: artist } = useQuery(queryArtist({ slug: params.slug, user }));

  const musicbrainzGenres = artist?.services?.musicbrainz?.genres
    ?.sort((a, b) => b.count - a.count)
    .slice(0, 3);
  const spotifyGenres = artist?.services?.spotify?.genres;

  if (!artist) {
    return null;
  }

  return (
    <>
      <Box mb={3}>
        <Stack direction="row">
          <Box mr={3} width={150}>
            <ArtistImage artist={artist} />

            <ArtistUserActions artist={artist} />
          </Box>

          <Stack direction="column">
            <Typography variant="h4">{artist.name}</Typography>
            <Typography variant="caption">
              <i>
                  {musicbrainzGenres?.length > 0
                    ? musicbrainzGenres?.map((genre, i) => {
                        return (
                          genre.name +
                          (i === musicbrainzGenres.length - 1 ? "" : ", ")
                        );
                      })
                    : spotifyGenres?.map((genre, i) => {
                        return (
                          genre + (i === spotifyGenres.length - 1 ? "" : ", ")
                        );
                      })}
              </i>
            </Typography>
          </Stack>
        </Stack>
      </Box>

      <FestivalsRow festivals={artist.editions} />
    </>
  );
}
