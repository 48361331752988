import Avatar from "@mui/material/Avatar";
import { Link } from "react-router-dom";

export const UserAvatar = ({ noLink, user }) => {
  if (!user?.email) {
    return <Avatar />;
  }

  if (noLink) {
    return (
      <Avatar
        alt={user.username}
        src={`https://www.gravatar.com/avatar/${user.email}?d=robohash`}
      />
    );
  }

  return (
    <Link to={`/user/${user.username ? user.username : user.email}`}>
      <Avatar
        alt={user.username}
        src={`https://www.gravatar.com/avatar/${user.email}?d=robohash`}
      />
    </Link>
  );
};
