import { useState } from "react";
import { useAuth } from "auth/use-auth";
import { Link } from 'react-router-dom';
import { sha256 } from 'js-sha256';

import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";

import { UserAvatar } from "views/components/user-avatar/user-avatar";

export const UserMenu = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { authToken, noAuthDialog, handleSignOut, user } = useAuth();

  const email256 = sha256(user?.email || '');

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  }

  const handleOnClick = () => {
    if (authToken) {
      setOpenDrawer(true);
    } else {
      noAuthDialog.setShowNoAuthDialog(true);
    }
  };

  const handleSignOutClick = () => {
    handleSignOut();
    setOpenDrawer(false);
  };

  return (
    <>
      <Box onClick={handleOnClick} sx={{ cursor: 'pointer' }}>
        <UserAvatar noLink user={user?.email ? { email: email256 } : {}} />
      </Box>

      <Drawer
        anchor={"right"}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <Link to={`/user/${email256}`} onClick={handleCloseDrawer}>My Page</Link>
        <Link to="/user/settings" onClick={handleCloseDrawer}>Settings</Link>
        <Button onClick={handleSignOutClick}>Sign Out</Button>
      </Drawer>
    </>
  );
};
