import { useAuth } from "auth/use-auth";
import { useQuery } from "@tanstack/react-query";
import { queryArtists } from "api/query/query-artists";

import { ArtistsRow } from "views/components/artists-row/artists-row";

export const loader =
  ({ queryClient, user }) =>
  async () => {
    const query = queryArtists({ user });

    return queryClient.ensureQueryData(query);
  };

export default function Artists() {
  const { user } = useAuth();
  const { data: artists } = useQuery(queryArtists({ user }));

  return (
    <>
      <ArtistsRow artists={artists} />
    </>
  );
}