import { LazyLoadImage } from "react-lazy-load-image-component";
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { Link } from "react-router-dom";

export const FestivalImage = ({ festival, link }) => {
  const size = 100;
  const scale = 1.5;
  const festivalImageSrc = festival?.image;

  const festivalImage = (
    <LazyLoadImage
      alt={festival.name}
      css={css`
        aspect-ratio: 1;
        border-radius: 50%;
        height: auto;
        object-fit: cover;
        max-width: 100%;
      `}
      height={size}
      src={`/images/festivals/${festivalImageSrc}`}
      width={size}
    />
  );

  if (!festival || !festival?.image) {
    return null;
  }

  return (
    <>
      {link ? (
        <Link to={`/festival/${festival?.slug}`}>{festivalImage}</Link>
      ) : (
        festivalImage
      )}
    </>
  );
};
