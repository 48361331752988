import { useLoaderData, Form } from "react-router-dom";
import { putUsername } from "api/user/user-settings";

export const action = ({ queryClient, user }) => async ({ request, params }) => {
  const formData = await request.formData();
  const updates = Object.fromEntries(formData);
  await putUsername({ ...updates, authToken: user?.accessToken });
  await queryClient.invalidateQueries({ queryKey: ['user-settings'] });

  return null;
}

export const SetUsername = ({ user }) => {
  return (
    <Form method="put" id="set-username">
      <input
        placeholder="Username"
        aria-label="Username"
        type="text"
        name="username"
        defaultValue={user?.username}
      />

      <button type="submit">Save</button>
    </Form>
  );
};
