import { useAuth } from "auth/use-auth";
import { useQueryClient } from "@tanstack/react-query";
import { usePutArtistAttended } from "api/query/put/use-put-artist-attended";
import { usePutArtistWatchlist } from "api/query/put/use-put-artist-watchlist";

import Stack from "@mui/material/Stack";

import {
  ActionButton,
  ActionButtonVariant,
} from "../components/action-button/action-button";

export const ArtistUserActions = ({ artist }) => {
  const { authToken, noAuthDialog, user } = useAuth();
  const queryClient = useQueryClient();
  const { mutate: artistAttended } = usePutArtistAttended();
  const { mutate: artistWatchlist } = usePutArtistWatchlist();

  const artistSlug = artist.slug;
  const user_attended_artist = artist.user_attended_artist;
  const user_watchlist_artist = artist.user_watchlist_artist;

  const handleToggleArtistAttended = () => {
    if (!authToken) {
     return noAuthDialog.setShowNoAuthDialog(true);
    }

    artistAttended(
      { attended: !user_attended_artist, authToken, slug: artist.slug },
      {
        onSettled: () => {
          queryClient.refetchQueries({
            queryKey: ["artist", artistSlug, user?.uid],
          });
        },
      },
    );
  };

  const handleToggleArtistWatchlist = () => {
    if (!authToken) {
     return noAuthDialog.setShowNoAuthDialog(true);
    }

    artistWatchlist(
      { add: !user_watchlist_artist, authToken, slug: artist.slug },
      {
        onSettled: () => {
          queryClient.refetchQueries({
            queryKey: ["artist", artistSlug, user?.uid],
          });
        },
      },
    );
  };

  return (
    <Stack alignItems="center" direction="row" justifyContent="center">
      <ActionButton
        on={user_attended_artist}
        onClick={handleToggleArtistAttended}
        variant={ActionButtonVariant.Artist}
      />

      <ActionButton
        on={user_watchlist_artist}
        onClick={handleToggleArtistWatchlist}
        variant={ActionButtonVariant.Watchlist}
      />
    </Stack>
  );
};
