import { useAuth } from "auth/use-auth";
import Box from "@mui/material/Box";
import { GravatarQuickEditorCore } from "@gravatar-com/quick-editor";

export const GravatarQuickEdit = ({ scope }) => {
  const { user } = useAuth();

    const quickEditorCore = new GravatarQuickEditorCore({
      email: user.email,
      scope: [
        "about",
        "avatars",
        "photos",
        "privacy",
        "verified-accounts",
      ],
      onProfileUpdated: () => {
        console.log("Profile updated!");
      },
      onOpened: () => {
        console.log("Editor opened!");
      },
    });

    console.log({ quickEditorCore });
    console.log(quickEditorCore);

  const handleOnClick = () => {
    quickEditorCore.open();
  };

  return <Box onClick={handleOnClick}>edit</Box>;
};
