import ErrorPage from "views/error/error_page";
import { Layout } from 'views/layout/layout';
import { SignIn } from 'views/auth/sign-in/sign-in';
import { SignUp } from 'views/auth/sign-up/sign-up';

export const routesAuth = [
  {
    path: "/sign-in",
    element: <Layout><SignIn /></Layout>,
    errorElement: <ErrorPage />,
  },
  {
    path: "/sign-up",
    element: <Layout><SignUp /></Layout>,
    errorElement: <ErrorPage />,
  },
];
