import { useAuth } from "auth/use-auth";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { queryUser } from "api/query/query-user";

import { UserHeader } from "views/user/components/user-header/user-header";
import { FestivalsRow } from "views/components/festivals-row/festivals-row";

export const loader =
  ({ queryClient, user }) =>
  async ({ params }) => {
    const query = queryUser({ slug: params.slug, user });

    return queryClient.ensureQueryData(query);
  };

export const User = () => {
  const { user } = useAuth();
  const params = useParams();
  const { data } = useQuery(queryUser({ slug: params.slug, user }));

  return (
    <>
      <UserHeader user={data} />
      <FestivalsRow festivals={data.festival_editions} />
    </>
  );
};
