import { useAuth } from "auth/use-auth";
import { useQueryClient } from "@tanstack/react-query";
import { usePutArtistAttended } from "api/query/put/use-put-artist-attended";
import { usePutArtistWatchlist } from "api/query/put/use-put-artist-watchlist";
import { usePutFestivalEditionArtistAttended } from "api/query/put/use-put-festival-edition-artist-attended";

import Stack from "@mui/material/Stack";

import {
  ActionButton,
  ActionButtonVariant,
} from "../components/action-button/action-button";

export const FestivalEditionArtistUserActions = ({ artist, festivalEditionSlug }) => {
  const { authToken, noAuthDialog, user } = useAuth();
  const queryClient = useQueryClient();
  const { mutate: artistAttended } = usePutArtistAttended();
  const { mutate: artistWatchlist } = usePutArtistWatchlist();
  const { mutate: festivalEditionAttended } =
    usePutFestivalEditionArtistAttended();

  const user_attended_artist = artist.user_attended_artist;
  const user_attended_artist_festival_edition =
    artist.user_attended_festival_edition_artist;
  const user_watchlist_artist = artist.user_watchlist_artist;

  const handleToggleArtistAttended = () => {
    if (!authToken) {
     return noAuthDialog.setShowNoAuthDialog(true);
    }

    artistAttended(
      { attended: !user_attended_artist, authToken, slug: artist.slug },
      {
        onSettled: () => {
          queryClient.refetchQueries({
            queryKey: ["festival_edition", festivalEditionSlug, user?.uid],
          });
        },
      },
    );
  };

  const handleToggleArtistWatchlist = () => {
    if (!authToken) {
     return noAuthDialog.setShowNoAuthDialog(true);
    }

    artistWatchlist(
      { add: !user_watchlist_artist, authToken, slug: artist.slug },
      {
        onSettled: () => {
          queryClient.refetchQueries({
            queryKey: ["festival_edition", festivalEditionSlug, user?.uid],
          });
        },
      },
    );
  };

  const handleToggleFestivalEditionArtistAttended = () => {
    if (!authToken) {
     return noAuthDialog.setShowNoAuthDialog(true);
    }

    festivalEditionAttended(
      {
        artist: artist.slug,
        attended: !user_attended_artist_festival_edition,
        authToken,
        festivalEdition: festivalEditionSlug,
      },
      {
        onSettled: () => {
          queryClient.refetchQueries({
            queryKey: ["festival_edition", festivalEditionSlug, user?.uid],
          });
        },
      },
    );
  };

  return (
    <Stack direction="row">
      {/*<ActionButton
        on={user_attended_artist_festival_edition}
        onClick={handleToggleFestivalEditionArtistAttended}
        variant={ActionButtonVariant.FestivalEditionArtist}
      />*/}

      <ActionButton
        on={user_attended_artist}
        onClick={handleToggleArtistAttended}
        variant={ActionButtonVariant.Artist}
      />

      <ActionButton
        on={user_watchlist_artist}
        onClick={handleToggleArtistWatchlist}
        variant={ActionButtonVariant.Watchlist}
      />
    </Stack>
  );
};
