import { useState } from "react";

import { useNavigate, Link } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export const SelectPreviousEdition = ({ festival_edition }) => {
  const [edition, setEdition] = useState("");
  const navigate = useNavigate();

  const handleChange = (event) => {
    navigate(`/festival/${event.target.value}`);

    setEdition(
      festival_edition.festival.editions.find(
        (edition) => edition.slug === event.target.value,
      ).name,
    );
  };

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  return mobile ? (
    <Box mb={1.5} width="100%" sx={{ whiteSpace: "nowrap" }}>
      <Box component="div" sx={{ overflow: "auto" }}>
      <Stack direction="row" spacing={2.5}>
        {festival_edition.festival.editions.map((edition) => (
          <Link to={`/festival/${edition.slug}`} key={edition.slug}>{edition.name.replace(`${festival_edition.festival.name} `, '')}</Link>
        ))}
        </Stack>
      </Box>
    </Box>
  ) : (
    <FormControl sx={{ minWidth: 250 }}>
      <Select
        displayEmpty
        value={edition}
        onChange={handleChange}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (!selected) {
            return <em>Go to another year</em>;
          }

          return selected;
        }}
        // MenuProps={MenuProps}
        inputProps={{ "aria-label": "Without label" }}
      >
        <MenuItem disabled value="">
          <em>Go to another year</em>
        </MenuItem>

        {festival_edition.festival.editions.map((edition) => (
          <MenuItem key={edition.slug} name={edition.name} value={edition.slug}>
            {edition.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
