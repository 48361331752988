import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useAuth } from "auth/use-auth";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

export const SignIn = () => {
	const authentication = getAuth();
	const { authToken, handleSignIn, handleSignOut } = useAuth();

	const handleSubmit = async (event) => {
		event.preventDefault();

		const email = event.target.email.value;
		const password = event.target.password.value;

		await signInWithEmailAndPassword(authentication, email, password).then(
			(response) => {
				handleSignIn({ user: response?.user });
			},
		);
	};

	if (authToken) {
		return <Button onClick={handleSignOut}>Sign Out</Button>;
	}

	return (
		<form id="sign-in" onSubmit={handleSubmit}>
			<Box display="inline" mr={2}>
				<TextField
					label="Email"
					name="email"
					type="text"
					size="small"
					sx={{ maxWidth: 150 }}
					variant="standard"
				/>
			</Box>

			<TextField
				label="Password"
				name="password"
				type="password"
				size="small"
				sx={{ maxWidth: 100 }}
				variant="standard"
			/>

			<Button type="submit">Sign In</Button>
		</form>
	);
};
