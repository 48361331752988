import AvatarGroup from "@mui/material/AvatarGroup";
import Box from "@mui/material/Box";
import { UserAvatar } from "views/components/user-avatar/user-avatar";

export const UserRow = ({ users, usersCount }) => {
  return (
    <Box display="flex" justifyContent="center">
      <AvatarGroup max={8} total={usersCount}>
        {users.map((user) => {
          return <UserAvatar user={user} />;
        })}
      </AvatarGroup>
    </Box>
  );
};
