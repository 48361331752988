import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { Link } from "react-router-dom";

import { ArtistImage } from "views/artist/components/artist-image/artist-image";

export const ArtistsRow = ({ artists }) => {
  if (!artists || !artists.length) {
    return;
  }

  return (
    <Grid container spacing={2}>
      {artists?.map((artist) => {
        return (
          <Grid item key={artist.slug} xs={4}>
            <Box mb={5}>
              <Link to={`/artist/${artist.slug}`}>
                <Box mb={0.5} textAlign="center">
                  <Typography variant="subtitle1">{artist.name}</Typography>
                </Box>

                <ArtistImage artist={artist} />
              </Link>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};
