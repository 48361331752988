import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import { UserAvatar } from "views/components/user-avatar/user-avatar";

export const UserHeader = ({ user }) => {
  return (
    <>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <UserAvatar user={user} />
        </Grid>
        <Grid item>{user.username}</Grid>
      </Grid>

      <Box p={2}>
        <Divider />
      </Box>
    </>
  );
};
