import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { useQueryClient } from "@tanstack/react-query";
import { useStoreAuth } from "stores/use-store-auth";

export const AuthContext = React.createContext(null);

export const AuthProvider = ({ children }) => {
  const auth = getAuth();
  const queryClient = useQueryClient();

  const {
    isSignedIn,
    signIn: storeSignIn,
    signOut: storeSignOut,
  } = useStoreAuth((state) => state);

  const [user, setUser] = useState(null);
  const [showNoAuthDialog, setShowNoAuthDialog] = useState(false);

  const handleSignIn = async ({ user }) => {
    setUser(user);

    if (!isSignedIn) {
      storeSignIn();
    }

    queryClient.invalidateQueries();
  };

  const handleSignOut = () => {
    signOut(auth).then(() => {
      setUser(null);

      storeSignOut();

      queryClient.invalidateQueries();
    });
  };

  const value = {
    authToken: user?.accessToken,
    handleSignIn,
    handleSignOut,
    noAuthDialog: { showNoAuthDialog, setShowNoAuthDialog },
    user,
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        handleSignIn({ user });
      } else {
        handleSignOut();
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  if (isSignedIn && !user) {
    return null;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
